export const load_curricula_for_mentee = `query LoadCurriculaForMentee($cohortId: ID = "", $menteeId: ID = "") {
  getCohort(id: $cohortId) {
    name
    curriculum(filter: {active: {ne: false}}) {
      items {
        deliverable
        duration
        id
        milestone
        objective
        order
        phase
        platform
        task
        validation
        enter_link
        link {
          items {
            id
            name
            target
          }
        }
        menteeProgress(filter: {menteeCurriculumProgressId: {eq: $menteeId}}) {
          items {
            status
            id
            validation_link
          }
        }
      }
    }
    mentors(filter: {menteeMentorId: {eq: $menteeId}}) {
      items {
        alias
        name
      }
    }
  }
}`;

export const complete_curriculum_progress = `mutation CompleteCurriculumProgress($status: CurriculumStatus = Complete, $menteeId: ID = "", $curriculumId: ID = "", $validation_link: String = "") {
  createMenteeCurriculumProgress(input: {menteeCurriculumProgressId: $menteeId, curriculumMenteeProgressId: $curriculumId, status: $status, validation_link: $validation_link}) {
    id
    status
    validation_link
    updatedAt
    createdAt 
    curriculum {
      phase
    }
  }
}`;

export const update_curriculum = `mutation AlterCurriculumProgress($status: CurriculumStatus = Incomplete, $id: ID = "", $validation_link: String = "") {
  updateMenteeCurriculumProgress(input: {id: $id, status: $status, validation_link: $validation_link}) {
    id
    status    
    validation_link
    updatedAt
    createdAt
    curriculum {
      phase
    }
  }
}`;

export const cohort_progress = `query cohortByName($name: String = "") {
  cohortByName(name: $name) {
    items {
      name
      curriculum(filter: {active: {ne: false}}) {
        items {
          menteeProgress {
            items {
              id
              mentee {
                id
              }
              status
            }
          }
          task
        }
      }
      mentees(limit: 250) {
        items {
          id
          firstName
          lastName
        }
      }
    }
  }
}
`;

export const mentorByAlias = `
query MyQuery($alias: String = "") {
  mentorByAlias(alias: $alias) {
    items {
      id
      cohortMentorsId
      menteeMentorId
      name
      mentee {
        alias
        id
        cohortMenteesId
        firstName
        lastName
      }
    }
  }
}
`;

export const menteeByAlias = `
query MyQuery($alias: String = "") {
  menteeByAlias(alias: $alias) {
    items {
      alias
      cohortMenteesId
      id
      firstName
      lastName
      cohort {
        id
        name
      }
      mentor {
        items {
          alias
          id
          name
          cohortMentorsId
          menteeMentorId
        }
      }
    }
  }
}
`;
